<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	
	import facebookLogin from 'facebook-login-vuejs';
	import GoogleLogin from 'vue-google-login';

	export default {
		extends: BaseVue,
		components: {
			facebookLogin,GoogleLogin
		},
		data() {
			return {
				input: {},
				params: {
                	clientId: '228499823754-glc0vf2m8l5gmhk4odsbgkunedciis4o.apps.googleusercontent.com'
				},
			}
		},
		computed: {
			mrValidation() {
				return this.$root.config.mrValidation.register
			},
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: "Register",
					link: { name: 'Register' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
				$("label[error]").hide()
				$("form").submit(function () {
					$("form label[error]").show();
				});
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});
			if(Gen.userToken() && Gen.user()) this.$router.push({name:'Dashboard'})
			this.getApi()
		},
		methods: {
			getApi() {
				this.$root.topProgress.done()
			},
			onSubmit(e) {
				if (e && e.btnLoading()) return;
				Gen.apirest('/registration', this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) return swal(err.resp.message, "", "warning")
					this.input = {}
					this.$root.user = resp.user
					Gen.putCookie("fotk", resp.token)
					Gen.putStorage("fo_user", resp.user)
					swal(resp.message, "", "success")
					this.$router.push({
						name: "Login"
					})
				}, "POST")
			},
			onSubmitFb(id,name) {
				Gen.apirest('/register-facebook', {
					id: id,
					name:name,
				}, (err, resp) => {
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")

					Gen.putCookie("fotk", resp.token)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					swal(resp.message, "", "success")
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			onSubmitGoogle(id,name,email) {
				Gen.apirest('/register-google', {
					id: id,
					name:name,
					email:email,
				}, (err, resp) => {
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					Gen.putCookie("fotk", resp.token)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					swal(resp.message, "", "success")
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			getUserData() {
				FB.login((response)=> {
					if (response.authResponse) {
						console.log('Welcome!  Fetching your information.... ');
						FB.api('/me',  (response)=> {
							// console.log('Good to see you, ' + response.name + '.');
							this.onSubmitFb(response.id,response.name)
						});
					} else {
						console.log('User cancelled login or did not fully authorize.');
					}
				});
			},
			onSuccess(googleUser) {
				let profile = googleUser.getBasicProfile();
				let id = profile.getId()
				let name = profile.getName()
				let email = profile.getEmail()
				// let id_token = googleUser.getAuthResponse().id_token;
				this.onSubmitGoogle(id,name,email)
			},
			onFailure(){

			},
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section notopmargin nobottommargin wall-bg">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<div class="box-el register">
							<div class="text-center">
								<h4>{{web.lbl_subscription}}</h4>
							</div>
							<div class="sign-options">
								<h5>{{web.lbl_reg_with}}</h5>
								<div class="d-flex justify-content-center">
									<GoogleLogin class="social-icon si-rounded si-google i-googel2 nopadding text-center" :params="params" :onSuccess="onSuccess"
										:onFailure="onFailure">
										<i class="icon-google"></i>
										<i class="icon-google"></i>
									</GoogleLogin>
									<facebook-login class="mx-0 w-auto pr-0" appId="1147877025559613" @login="getUserData" loginLabel=""></facebook-login>
								</div>
							</div>
							<div class="divider divider-center divider-short mb-3 mt-4">
								<strong>OR</strong>
							</div>
							<VForm @resp="onSubmit">
								<VInfo ref="info"></VInfo>
								<div class="form-group">
									<label for="">{{web.lbl_name}}<span class="text-red txt-req">*</span></label>
									<input type="text" name="fullname" v-model="input.fullname"
										onkeydown="return fullNameKey(event)" class="form-control"
										v-bind="validation('fullname')" :placeholder="web.lbl_name">
									<LabelError name="fullname"></LabelError>
								</div>
								<div class="form-group">
									<label for="">{{web.lbl_email}}<span class="text-red txt-req">*</span></label>
									<input type="email" name="email" v-model="input.email"
										onkeydown="return emailKey(event)" class="form-control"
										v-bind="validation('email')" :placeholder="web.lbl_email">
									<LabelError name="email"></LabelError>
								</div>
								<div class="form-group">
									<label for="">{{web.lbl_phone}}<span class="text-red txt-req">*</span></label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">+62</span>
										</div>
										<InputNumber name="phone" type="PHONE" v-model="input.phone" class="form-control"
										placeholder="08xx-xxxx-xxxx" v-bind="validation('phone')"></InputNumber>
									</div>
									
									<LabelError name="phone"></LabelError>
								</div>
								<!-- <div class="form-group">
									<label for="">City/District<span class="text-red txt-req">*</span></label>
									<input type="text" class="form-control">
								</div> -->
								<div class="form-group">
									<label for="">{{web.lbl_address}}<span class="text-red txt-req">*</span></label>
									<textarea name="address" v-model="input.address" id=""
										v-bind="validation('address')" cols="30" rows="4"
										class="form-control" :placeholder="web.lbl_address"></textarea>
									<LabelError name="address"></LabelError>

								</div>
								<div class="form-group view_password">
									<label for="">{{web.lbl_password}}<span class="text-red txt-req">*</span></label>
									<input type="password" name="password" id="password" v-model="input.password"
										v-bind="validation('password')" class="form-control" :placeholder="web.lbl_password">
									<div class="togglepass">
										<i class="btn_view icon-eye-close"></i>
									</div>
								</div>
								<div class="form-group view_password">
									<label for="">{{web.lbl_confirm_password}}<span class="text-red txt-req">*</span></label>
									<input type="password" name="confirm_password" v-model="input.confirm_password"
										v-bind="validation('confirm_password')" class="form-control" :placeholder="web.lbl_confirm_password">
									<div class="togglepass">
										<i class="btn_view icon-eye-close"></i>
									</div>
								</div>
								<div class="form-group">
									<div>
										<input id="checkbox-11" v-model="input.agree_term" class="checkbox-style"
											name="checkbox-11" type="checkbox">
										<label for="checkbox-11" class="checkbox-style-3-label"><span v-html="web.lbl_reg_agreement"></span></label>
									</div>
								</div>
								<div class="form-group">
									<button type="submit" class="button button-red btn-loading">{{web.btn_submit}}</button>
								</div>
							</VForm>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>